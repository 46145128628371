import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment'
import 'rxjs/add/observable/of';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private dataUrl = 'Dashboard';

    constructor(
        private restService: RestService
    ){}

    public GetAllDashboardData() {
        return this.restService.get(this.dataUrl);
    }

    public GetAllDashboardWeekLabels (){
        var weeks = [];
        var week = this.getEndWeek()

        for (let i = 0; i < 8; i++) {
            const weekLabel = week.format("DD/MM/YY")
            weeks.unshift(weekLabel);
            week = week.subtract(1, 'week');
        }

        return Observable.of(weeks).delay(0);
    }

    public GetLocationDashboardById( id: number)
    {
        return this.restService.get(`${this.dataUrl}/${id}`);
    }

    private getEndWeek()
    {
        var date = moment();
        var days = date.day();

        if(days <= 3){
            // Ajuste al miercoles de esa semana
            return date.add( 3 - days, 'days')
        }

        //Ajuste al miercoles de la próxima semana
        return date.subtract(days - 3, 'days')
            .add(1, 'week');
    }
    
    public GetMotocyclesDataDashboard()
    {
        return this.restService.get(`${this.dataUrl}/motocycles`);
    }

    public GetMotocycles()
    {
        return this.restService.get(`${this.dataUrl}/motocycles/lastAssigned`);
    }
}