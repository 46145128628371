import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { ExternalServiceService } from '../_services/external-service.service';

@Injectable()
export class ExternalServiceDataResolver implements Resolve<Observable<any>> {

  constructor(
    private serviciosexternosService: ExternalServiceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    switch (route.data.section) {
      case "getTableData": {
        return this.serviciosexternosService.getDataForDataTable();
      }
      case "getDataToEdit": {
        return Observable.forkJoin(
          this.serviciosexternosService.getExternalServiceById( route.params['id'] ),
        )
      }
    }
    return Observable.of(null).delay(0);
  }
}
