import { Injectable } from '@angular/core';
import { NavData } from '../_nav';
import * as data from  '../../routes.json';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavService
{

  private navItems: NavData[];
  private claims: any;

  constructor(private authService: AuthService)
  {
    this.setRoutes(data);
  }

  public setRoutes (routes: Object)
  {
    this.navItems = this.processRoutes(routes["default"]["menuItems"]);
  }

  public getRoutes(): NavData[]
  {
    return this.navItems;
  }

  private verifyPermission(permissions) {
    let hasPermission = false;
    if ( this.claims) {
      for (let checkPermission of permissions) {
        const permissionFound = this.claims.find(x => x === checkPermission);
        if (permissionFound) {
          hasPermission = true;
          break;
        }
      }
    }
    return hasPermission;
  }

  private processRoutes(routes : Array<Object>) : NavData[]
  {
    let navigations = [];
    if(routes == null){
      return [];
    }


    for (let route of routes) {
      if(routes != null){
        if ( ( typeof route["hide"] == undefined ) || ( typeof route["hide"] == "undefined" ) || ( route["hide"] != "true" )){
          let navs = undefined;
          if ( ( typeof route["menuItems"] != undefined ) && ( typeof route["menuItems"] != "undefined" )){
            navs = ( route["menuItems"].length == 0 ) ? undefined : this.processRoutes(route["menuItems"]);
          }
          let url = route["url"] == null ? "/" : route["url"];
          let permissions = route["permissions"];

          for (let checkPermission of permissions) {
            if (this.authService.validatePermission(checkPermission)) {
              navigations.push(
              {
                name: route["resourceName"],
                url:  url,
                icon: route["image"],
                permissions: route["permissions"],
                children: ( typeof navs == undefined ) ? undefined : navs
              });
            }
          }
        }
      }
    };

    return navigations;
  }


}
