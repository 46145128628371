import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { NavData } from '../../_nav';
import { AuthService } from '../../_services';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
  public navItems: NavData[];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  constructor(
      private authService : AuthService,
      navService : NavService,
      @Inject(DOCUMENT) _document?: any
  )
  {

    this.navItems = navService.getRoutes();
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });

    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
  logout(){
    this.authService.logout();
  }
}
