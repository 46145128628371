import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService, StorageService } from '../../_services';
import {  Location } from '@angular/common';
import { LoginObject } from '../../_models/login_object';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { SessionLogin } from '../../_models/session';
import { Router } from '@angular/router';
import { ValidationForm } from '../usuarios/form-user/form-user.validationForm';
import { AuthorizatedGuard } from '../../_guards/authorizated.guard';
import { MessageService } from '../../_services';
import { MessagesEnum } from '../../_enums/messages.enum';
import {environment} from '../../../environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import Swal from 'sweetalert2';

@Component({
  templateUrl: 'login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['login.components.scss']
})

export class LoginComponent {
  public user: LoginObject;
  public loginForm: FormGroup;
  public submitted: Boolean = false;
  public error: { code: number, message: string } = null;
  public redirect: string[] = [];
  public currentApplicationVersion: string;

  constructor(
    private authenticationService: AuthService,
    private authorizatedGuard: AuthorizatedGuard,
    private formBuilder: FormBuilder,
    private location: Location,
    private storageService: StorageService,
    private router: Router,
    public validatonForm: ValidationForm,
    private messageService: MessageService,
    private spinner: Ng4LoadingSpinnerService,
  ) {
    this.loginForm = this.validatonForm.errorMessages;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    if(environment){
      this.currentApplicationVersion = environment.appVersion;
    }
    this.redirect = this.location.path().split('/');
    if (this.authorizatedGuard.canActivate())
      this.redirectPage();
    else {
      if (localStorage.getItem("redirect"))
        this.messageService.showMessage(MessagesEnum.Sesion_cerrar);
      else
        this.messageService.showMessage(MessagesEnum.No_sesion);
      localStorage.removeItem("redirect");
    }
  }

  get f() { return this.loginForm.controls; }

  async onSubmit() {
    this.error = null;
    if (this.loginForm.invalid) {
      Swal.fire({
        title: '<strong>Datos <u>incorrectos</u></strong>',
        icon: 'info',
        html:
          'Por favor ingresa <b>usuario</b>, y ' +
          '<b>contraseña</b> ' +
          'para acceder',
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Continuar',
        confirmButtonAriaLabel: 'Thumbs up, great!'
      })
    } else {
      if (this.loginForm.valid) {
        this.user = {
          username: this.loginForm.value.username,
          password: this.loginForm.value.password,
          remember: 0
        }
        this.spinner.show();

        await this.authenticationService.login(this.user).subscribe(data => {
          this.spinner.hide();
          this.correctLogin(data);
        },
          (error) => this.spinner.hide());
      }
    }
  }

  private correctLogin(data: SessionLogin) {
    this.authenticationService.setPermissions(data.claimsAssign);
    this.storageService.setCurrentSession(data);
    this.redirectPage();
  }

  redirectPage() {
    this.router.navigate(['/dashboard']);
  }
}
