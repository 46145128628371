import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './_services';
import { isNullOrUndefined } from 'util';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['../scss/vendors/toastr/toastr.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ AuthService ],
  styles: [
    '.app { background-image: none; }'
  ],
})

export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private spinner: Ng4LoadingSpinnerService,
  ) { }

  ngOnInit() {
    // this.spinner.show(); TODO:Descomentar
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
