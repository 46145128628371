import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { StorageService } from "../_services";
import { MessagesEnum } from '../_enums/messages.enum';
import { MessageService } from '../_services';

@Injectable()
export class AuthorizatedGuard implements CanActivate {

  permissions: Array<string>;


  constructor(
    private router: Router,
    private storageService: StorageService,
    private messageService: MessageService,
  ){ }

  canActivate() {
    if (this.storageService.isAuthenticated()) {
      // logged in so return true
      this.messageService.showMessage(MessagesEnum.Bienvenido);
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/login']);
  }
}
