import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { UserService } from '../_services';
import { User } from '../_models';

@Injectable()
export class UserDataResolver implements Resolve<Observable<User>> {

  constructor(
    private userService: UserService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    switch (route.data.section) {
      case "getTableData": {
        return this.userService.getDataForDataTable();
      }
    }
    return Observable.of(null).delay(0);
  }
}
