import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { RestService } from './rest.service';
import { MessageService } from './message.service'
import {LoginObject} from "../_models/login_object";
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { MessagesEnum } from '../_enums/messages.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import swal from 'sweetalert2';

@Injectable()
export class AuthService {

  user: BehaviorSubject<any> = new BehaviorSubject(null);
  userData:any;
  state:any;
  permissions: any[];
  auth:any;
  isLogged: boolean;

  public static refreshUrl = 'users/refreshAuthentication';
  public static loginUrl = 'users/authenticate'; // endpoint

  constructor(
    private restService: RestService,
    private router: Router,
    private storageService: StorageService,
    private messageService: MessageService
  ) {}

  login(loginObj: LoginObject)
  {
    return this.restService.post(AuthService.loginUrl ,loginObj, undefined, this.loginHandleError);
  }

  logout()
  {
    this.storageService.removeCurrentSession();
    localStorage.setItem("redirect", "1");
    this.router.navigate(['/login']);
  }

  refreshSesion() : Observable<any>
  {
    var token = this.storageService.getRefreshToken();
    return this.restService.post(AuthService.refreshUrl, {token}, undefined, this.refreshHandleError.bind(this))
  }

  setPermissions(data){
    localStorage.removeItem('permissions');
    localStorage.setItem('permissions', JSON.stringify(data) );
  }

  getPermissions(){
    return JSON.parse(localStorage.getItem('permissions'));
  }

  validatePermission(query){
    return true;
    let listPermissions = JSON.parse(localStorage.getItem('permissions'));
    return listPermissions.find(x => x === query);
  }

  private loginHandleError(error: HttpErrorResponse) {
    let message;
    console.log(error);

    if(error.status == 401){
      message = 'Usuario o contraseña inválida'
    } else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message = 'Se ha presentado un error:'+ error.error.message;
      console.error(message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      message = 'Se ha presentado un error, por favor intente más tarde.<br/> error code:'+ error.status + '<br/> error:'+error.message;
      console.error('El servicio devolvió un error código ' + error.status + ' con mensaje: ' + error.message);
    }
    // return an observable with a user-facing error message
    swal.fire('Error',message,'error');
    return throwError(message);
  };

  private refreshHandleError(error : HttpErrorResponse) {
    if(error.status != 401){
        return throwError(error)
    }

    swal.fire('Advertencia', '¡Su Sesión a expirado! Por favor inicie sesión para continuar de nuevo');
    this.storageService.removeCurrentSession()
    this.router.navigate(['/login']);
  }
}
