import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

@Injectable()
export class RestInterceptor implements HttpInterceptor{

    intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>>{

        return next.handle(request);
    }
}
