import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Headers } from "@angular/http";
import { RestResult } from '../_models';
import { Observable } from 'rxjs/Rx';
import { throwError } from 'rxjs';
import swal from 'sweetalert2';
import 'rxjs/Rx';

@Injectable()
export class RestService {
  result: RestResult;
  headers = new Headers();
  ul = '';
  constructor(
    private client: HttpClient,
    private storageService: StorageService,
  ) { }

  private GetUrl(endpoint: string): string {
    switch (window.location.hostname) {
      case 'localhost': {
        let index = (' '+endpoint.toLowerCase()).indexOf( "mock" );
        if(index > 0)
          return environment.apiTestUrlBase + endpoint;
        return environment.apiUrlBase + endpoint;
      }
      default: {
        return environment.apiUrlBase + endpoint;
      }
    }
  }

  post(endpoint: string, data?: any, type?: string, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.storageService.getCurrentToken()
      })
    };

    let body = JSON.stringify(data);
    let result = this.client.post<any>(this.GetUrl(endpoint), body, options).pipe(catchError(errorHandler));
    return result;
  }

  postFiles(endpoint: string, data?: any, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.storageService.getCurrentToken()
      })
    };

    let formData = new FormData();

    for (var key in data) {
      formData.append(key.charAt(0).toUpperCase() + key.slice(1), data[key]);
    }

    return this.client.post<any>(this.GetUrl(endpoint), formData, options).pipe(catchError(errorHandler));
  }

  get(endpoint: string, data?: any, type?: string, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.storageService.getCurrentToken()
      }),
      params: data
    };

    return this.client.get<any>(this.GetUrl(endpoint), httpOptions).pipe(catchError(errorHandler));
  }

  getMap(endpoint: any, data?: any, type?: string, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.storageService.getCurrentToken(),
      }),
      params: data
    };
    return this.client.get<any>(endpoint, httpOptions).pipe(catchError(errorHandler));
  }

  getDocument(endpoint: string, data?: any, type?: string, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.storageService.getCurrentToken(),
      responseType: 'blob',
      Accept: 'application/octet-stream',
      observe: 'response'
    });


    return this.client.get(this.GetUrl(endpoint), { headers: headers, responseType: "blob", params: data }).pipe(catchError(errorHandler));

  }

  put(endpoint: string, data?: any, type?: string, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.storageService.getCurrentToken()
      })
    };

    let body = JSON.stringify(data);
    return this.client.put<any>(this.GetUrl(endpoint), body, options).pipe(catchError(errorHandler));
  }

  patch(endpoint: string, data?: any, type?: string, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.storageService.getCurrentToken()
      })
    };

    let body = JSON.stringify(data);
    return this.client.patch<any>(this.GetUrl(endpoint), body, options).pipe(catchError(errorHandler));
  }

  delete(endpoint: string, data?: any, type?: string, errorHandler: (err: any, caught: Observable<unknown>) => Observable<never> = this.handleError): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.storageService.getCurrentToken()
      }),
      params: data
    };

    return this.client.delete<any>(this.GetUrl(endpoint), httpOptions).pipe(catchError(errorHandler));
  }

  private handleError(error: HttpErrorResponse, caught: Observable<unknown>) {
    let message;
    console.log(error);
    if (error.status == 400) {
      message = error.error[0];
    }
    else if(error.status == 499){
      // A timeout error
      message = 'Se ha presentado un error de tiempo:'+ error.error.message;
      console.error(message);
    }
    else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message = 'Se ha presentado un error:' + error.error.message;
      console.error(message);
    }
    else if (error.status == 404) {
      // A client-side or network error occurred. Handle it accordingly.
      message = 'Se ha presentado un error, por favor intente más tarde. (00):';
      console.error('El servicio devolvió un error código ' + error.status + ' con mensaje: ' + error.message);
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      message = 'Se ha presentado un error, por favor intente más tarde.<br/> error code:' + error.status + '<br/> error:' + error.message;
      console.error('El servicio devolvió un error código ' + error.status + ' con mensaje: ' + error.message);
    }
    // return an observable with a user-facing error message
    swal.fire('Error', message, 'error');
    return throwError(message);
  };
}
