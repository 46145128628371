import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AuthorizatedGuard } from './_guards/authorizated.guard';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Error 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Error 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Ingresar'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthorizatedGuard],
    data: {
      title: 'Inicio'
    },
    children: [
      {
        path: 'catalog/requisiciones',
        loadChildren: './views/catalog/compras/requisiciones/requisiciones.module#RequisicionesModule'
      },
      {
        path: 'catalog/cotizaciones',
        loadChildren: './views/catalog/compras/cotizaciones/cotizaciones.module#CotizacionesModule'
      },
      {
        path: 'catalog/orden-compra',
        loadChildren: './views/catalog/compras/orden-compra/orden-compra.module#OrdenCompraModule'
      },
      {
        path: 'catalog/manejo-medicamento',
        loadChildren: './views/catalog/manejo-medicamentos/manejo-medicamento.module#ManejoMedicamentoModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'catalog/cargo-empresa',
        loadChildren: './views/catalog/cargo-empresa/cargo-empresa.module#CargoEmpresaModule'
      },
      {
        path: 'catalog/activo-fijo',
        loadChildren: './views/catalog/activos-fijos/activo-fijo.module#ActivoFijoModule'
      },
      {
        path: 'catalog/tipo-articulo',
        loadChildren: './views/catalog/tipo-articulo/tipo-articulo.module#ItemTypeModule'
      },
      {
        path: 'catalog/cargos-directos',
        loadChildren: './views/catalog/cargos-directos/cargos-directos.module#DirectChargesModule'
      },
      {
        path: 'catalog/presupuestos',
        loadChildren: './views/catalog/presupuestos/presupuesto.module#BudgetsModule'
      },
      {
        path : 'catalogo/clientes',
        loadChildren : './views/catalog/customers/customers.module#CustomersModule'
      },
      {
        path: 'catalog/entrada-salida-dinero',
        loadChildren: './views/catalog/input-output-money/input-output-money.module#InputOutputMoneyModule'
      },
      {
        path: 'catalog/gastos',
        loadChildren: './views/catalog/gastos/gastos.module#ExpensesModule'
      },
      {
        path: 'reportes',
        loadChildren: './views/reportes/report.module#ReportsModule'
      },
      {
        path: 'catalog/contactos',
        loadChildren: './views/catalog/contacts/contacts.module#ContactsModule'
      },
      {
        path: 'catalog/entradas-salidas',
        loadChildren: './views/catalog/entradas-salidas/inputs-outputs.module#InputsOutputsModule'
      },
      {
        path: 'catalog/tipo-convenio',
        loadChildren: './views/catalog/agreement-type/agreement-type.module#AgreementTypeModule'
      },
      {
        path: 'catalog/comisiones-honorarios',
        loadChildren: './views/catalog/honorary-commissions/honorary-commissions.module#HonoraryComissionsModule'
      },
      {
        path: 'catalog/estatus-credito',
        loadChildren: './views/catalog/credit-status/creditStatus.module#CreditStatusModule'
      },
      {
        path: 'catalog/comisiones-bancarias',
        loadChildren: './views/catalog/comisiones-bancarias/bank-fees.module#BankFeesModule'
      },
      {
        path : 'catalog/nombres',
        loadChildren : './views/catalog/generic-names/generic-names.module#GenericNamesModule',
      },
      {
        path: 'catalog/empresas',
        loadChildren: './views/catalog/companies/companies.module#CompaniesModule'
      },
      {
        path: 'catalog/servicios-externos',
        loadChildren: './views/catalog/external-service/external-service.module#ExternalServiceModule',
      },
      {
        path: 'catalog/bancos-sat',
        loadChildren: './views/catalog/sat-bank/sat-bank.module#SatBankModule'
      },
      {
        path: 'catalog/articulos',
        loadChildren: './views/catalog/articles/articles.module#ArticlesModule'
      },
      {
        path: 'catalog/entrada-y-salida-de-dinero',
        loadChildren: './views/catalog/input-output-money/input-output-money.module#InputOutputMoneyModule'
      },
      {
        path: 'catalog/comprobacion-de-gastos',
        loadChildren: './views/catalog/checking-costs/checking-costs.module#CheckingCostsModule'
      },
      {
        path: 'catalog/otros-movimientos-pagos',
        loadChildren: './views/catalog/other-paid-movements/other-paid-movements.module#OtherPaidMovementsModule'
      },
      {
        path: 'catalog/negocios-rotacion',
        loadChildren: './views/catalog/negocios-rotacion/bussines.module#BussinesModule'
      },
      {
        path: 'catalog/firmas',
        loadChildren: './views/catalog/signatures/signatures.module#SignaturesModule'
      },
      {
        path: 'catalog/centro-costos',
        loadChildren: './views/catalog/cost-centers/cost-center.module#CostCenterModule'
      },
      {
        path: 'catalog/otros-conceptos-cargos',
        loadChildren: './views/catalog/other-charge-concepts/other-concept-charge.module#OtherConceptChargeModule'
      },
      {
        path: 'catalog/requisiciones-automaticas-de-faltantes',
        loadChildren: './views/catalog/automatic-missing-requisitions/automatic-missing-requisitions.module#AutomaticMissingRequisitionsModule'
      },
      {
        path: 'catalog/deudores-incobrables',
        loadChildren: './views/catalog/bad-debtors/bad-debtors.module#BadDebtorsModule'
      },
      {
        path: 'catalog/empresas-contables',
        loadChildren: './views/catalog/accounting-companies/accounting-companies.module#AccountingCompaniesModule'
      },
      {
        path: 'catalog/grupos-cargo',
        loadChildren: './views/catalog/charge-group/charge-group.module#ChargeGroupModule'
      },
      {
        path: 'catalog/tarifas-isr',
        loadChildren: './views/catalog/tarifas-isr/catalog-rates.module#CatalogRatesModule'
      },
      {
        path: 'catalog/conceptos_facturas',
        loadChildren: './views/catalog/invoice-concepts/invoice-concepts.module#InvoiceConceptsModule'
      },
      {
        path: 'catalogo/cuentas_contables',
        loadChildren: './views/catalog/accounts/accounts.module#AccountsModule'
      },
      {
        path: 'catalog/proveedores',
        loadChildren: './views/catalog/providers/providers.module#ProvidersModule'
      },
      {
        path: 'catalog/grupo-tipo-clientes',
        loadChildren: './views/catalog/client-type-group/client-type-group.module#ClientTypeGroupModule'
      },
      {
        path: 'catalog/elementosfijos',
        loadChildren: './views/catalog/elementosfijos/fixedelements.module#FixedElementsModule'
      },
      {
        path: 'catalog/formas-pagos',
        loadChildren: './views/catalog/payments/payments.module#PaymentsModule'
      },
      {
        path: 'catalog/conceptos-caja',
        loadChildren: './views/catalog/petty-cash/petty-cash.module#PettyCashModule'
      },
      {
        path: 'shopping/autorizacion-servicios-internos',
        loadChildren: './views/shopping/internal-services-authorization/internal-services-authorization.module#InternalServicesAuthorizationModule'
      },
      {
        path: 'shopping/orden-compra-servicios-internos',
        loadChildren: './views/shopping/internal-services-purchase-order/internal-services-purchase-order.module#InternalServicesPurchaseOrderModule'
      },
      {
        path: 'shopping/cotizacion-servicios-internos',
        loadChildren: './views/shopping/internal-services-quote/internal-services-quote.module#InternalServicesQuoteModule'
      },
      {
        path: 'shopping/requisicion-servicios-internos',
        loadChildren: './views/shopping/internal-services-requisition/internal-services-requisition.module#InternalServicesRequisitionModule'
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
