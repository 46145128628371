import { from } from 'rxjs';

export * from './user.data.resolver';
export * from './reports.data.resolvers';
export * from './creditStatus.data.resolvers';
export * from './bussines-rotation.resolvers';
export * from './agreement-type.data.resolvers';
export * from './catalog-bad-debtors-list.data.resolvers';
export * from './contacts.data.resolver';
export * from './customer.data.resolver';
export * from './catalog-sat.resolvers';
export * from './catalog-articles.resolvers';
export * from './catalog.data.resolvers'
export * from './bank-fees.data.resolver';

export * from './charge-group.data.resolver'
export * from './external-service.data.resolver';

export * from './catalog-inputs-outputs.resolvers'

export * from './payments.data.resolver';
export * from './client-type-group.data.resolver';

export * from './input-output-money.resolver';
export * from './other-payment-movements.resolver';