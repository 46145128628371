import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { SessionLogin } from "../_models/session";
import { User } from "../_models/user";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class StorageService {
  private storageValue;
  private currentSession : SessionLogin = null;
  private expiredDate;

  constructor(
      private router: Router,
      private cookieService: CookieService,
  ) {
    this.storageValue = cookieService
    this.currentSession = this.loadSessionData();
  }

  setCurrentSession(session: SessionLogin): void {
    console.log(session)
    this.currentSession = session;
    var t = new Date();
    t.setSeconds(t.getSeconds() + session.accessToken.expiresIn);
    this.expiredDate =  t;
    this.cookieService.set('currentUser', JSON.stringify(session), this.expiredDate, undefined, undefined, undefined, "Lax");
  }

  loadSessionData(): SessionLogin{
    var sessionStr = this.storageValue.get('currentUser');
    return (sessionStr) ? <SessionLogin> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): SessionLogin {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.storageValue.delete('currentUser');
    this.currentSession = null;
  }

  getCurrentUser(): User {
    var session: SessionLogin = this.getCurrentSession();
    return (session && session.userId) ? session : null;
  }

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  }

  setPermissions(data){
    localStorage.removeItem('permissions');
    localStorage.setItem('permissions', JSON.stringify(data) );
  }

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.accessToken.token) ? session.accessToken.token : null;
  }

  getRefreshToken(): string {
    var session = this.getCurrentSession();
    return (session && session.refreshToken.token ) ? session.refreshToken.token : null;
  }

  logout(): void{
    this.removeCurrentSession();
    this.router.navigate(['/login']);
  }

  isJwtExpired(): boolean{
    var jwt = this.getCurrentToken();
    var b64 = jwt.split(".")[1];
    var sdata = atob(b64);
    var data = JSON.parse(sdata);
    var expiredDate = new Date(data.exp * 1000)
    var now = new Date();

    return expiredDate < now;
  }
}
