
import { AppAsideModule, AppBreadcrumbModule, AppHeaderModule, AppFooterModule, AppSidebarModule } from '@coreui/angular';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MessagesModule } from './utils/messages/messages.module';
import { ToasterModule,ToasterService } from 'angular2-toaster';
import { AuthorizatedGuard } from "./_guards/authorizated.guard";
import { Permissions } from './_guards/permissions.guard';
import { CookieService } from 'ngx-cookie-service';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTableModule } from 'angular2-datatable';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// Import containers
import { DefaultLayoutComponent } from './containers';


// Layouts
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { AppComponent } from './app.component';


// Modules
import { BadDebtorsRoutingModule } from './views/catalog/bad-debtors/bad-debtors-routing.module';
import { BadDebtorsModule } from './views/catalog/bad-debtors/bad-debtors.module';
import { CreditStatusRoutingModule } from './views/catalog/credit-status/creditStatus-routing.module';
import { CreditStatusModule } from './views/catalog/credit-status/creditStatus.module';
import { ContactsRoutingModule } from './views/catalog/contacts/contacts-routing.module';
import { ContactsModule } from './views/catalog/contacts/contacts.module';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';


// Import Services
import{ UserService } from './_services/users.service';
import{ PermissionService } from './_services/permissions.service';
import{ AuthService } from './_services/auth.service';
import{ StorageService } from './_services/storage.service';
import{ DashboardService } from './_services/dashboard.service';
import{ ReportService } from './_services/report.service';
import{ RestService } from './_services/rest.service';
import{ MessageService } from './_services/message.service';
import{ BankFeesService } from './_services/bank-fees.service';
import { CatalogComisionesService } from './_services/catalog-comisiones.service';
import { CatalogService } from './_services/catalog-inputs-outputs.service';
import { CatalogOtherConceptsChargeService } from './_services/catalog-other-concepts-charge.service';
import { ExternalServiceService } from './_services/external-service.service';
import { CargoEmpresaService } from './_services/cargo-empresa-service/cargo-empresa.service';
import { FixedAssetsService } from './_services/activo-fijo-service/activo-fijo.service';
import { MedicineManagementService } from './_services/manejo-medicamento-service/manejo-medicamento.service';
import { ProvidersService } from './_services/providers.service';
import { ClientTypeGroupService } from './_services/client-type-group.service';
import { ExpensesService } from './_services/gasto-service/gasto.service';
import { ChargeGroupService } from './_services';
import { ItemTypeService } from './_services/item-type-service/item-type.service';
import { PettyCashService } from './_services/petty-cash.service';
import { DepartmentsService } from './_services/departments.service';


// Import Resolvers
import { UserDataResolver } from './_resolvers/user.data.resolver';
import { ReportDataResolver } from './_resolvers/reports.data.resolvers';
import { CatalogSatResolver } from './_resolvers/catalog-sat.resolvers';
import { CatalogOtrosConceptosCargoResolver } from './_resolvers/catalog-other-concepts-charge.resolvers';
import { ExternalServiceDataResolver } from './_resolvers/external-service.data.resolver';
import { BankFeesDataResolver } from './_resolvers/bank-fees.data.resolver';
import { CatalogResolver } from './_resolvers/catalog.data.resolvers';
import { CheckingCostsDataResolver } from './_resolvers/checking-costs-data.resolver';
import { OtherPaymentMovementsResolver } from './_resolvers/other-payment-movements.resolver';
import { InputOutputMoneyResolver } from './_resolvers/input-output-money.resolver';
import { ClientTypeGroupDataResolver } from './_resolvers/client-type-group.data.resolver';
import { ExpensesDataResolver } from './_resolvers/expenses.data.resolver';
import { MedicineManagementDataResolver } from './_resolvers/medicine-management.data.resolver';
import { FixedAssetsDataResolver } from './_resolvers/fixed-assets.data.resolver';
import { ChargeGroupDataResolver } from './_resolvers/charge-group.data.resolver';
import { ItemTypeDataResolver } from './_resolvers/item-type.data.resolver';
import { PettyCashDataResolver } from './_resolvers/petty-cash.data.resolver';

// Import helpers
import { RestInterceptor } from './_Interceptors/rest.interceptor';



@NgModule({
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    ToasterModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    HttpModule,
    HttpClientModule,
    MessagesModule,
    ReactiveFormsModule,
    CommonModule,
    CreditStatusRoutingModule,
    CreditStatusModule,
    ContactsRoutingModule,
    ContactsModule,
    FormsModule,
    DataTableModule,
    BadDebtorsRoutingModule,
    BadDebtorsModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthorizatedGuard,
    Permissions,
    CookieService,

    //Inject Services
    RestService,
    MessageService,
    UserService,
    PermissionService,
    AuthService,
    ToasterService,
    StorageService,
    DashboardService,
    ReportService,
    ProvidersService,
    BankFeesService,
    CatalogComisionesService,
    CatalogService,
    FixedAssetsService,
    MedicineManagementService,
    CargoEmpresaService,
    CatalogOtherConceptsChargeService,
    ExternalServiceService,
    ExpensesService,
    ClientTypeGroupService,
    ChargeGroupService,
    ItemTypeService,
    PettyCashService,
    DepartmentsService,

    //Inject Reolvers
    UserDataResolver,
    ReportDataResolver,
    BankFeesDataResolver,
    CatalogSatResolver,
    CatalogResolver,
    CatalogOtrosConceptosCargoResolver,
    ExternalServiceDataResolver,
    ExpensesDataResolver,
    MedicineManagementDataResolver,
    FixedAssetsDataResolver,
    CheckingCostsDataResolver,
    OtherPaymentMovementsResolver,
    InputOutputMoneyResolver,
    ClientTypeGroupDataResolver,
    ChargeGroupDataResolver,
    ItemTypeDataResolver,
    PettyCashDataResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
