import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterConfig } from '../../../../node_modules/angular2-toaster';
import { MessageService } from '../../_services/message.service';
import { Message } from '../../interfaces/message.interface';
import { MessagesEnum } from '../../_enums/messages.enum';
import { AuthService } from '../../_services/auth.service';
import { isNullOrUndefined } from 'util';
import { StorageService } from '../../_services';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styles: []
})
export class MessagesComponent implements OnInit {
  private toasterService: ToasterService;
  public toasterconfig : ToasterConfig = new ToasterConfig({
                                                          tapToDismiss: true,
                                                          timeout: 5000
                                                        });

  constructor(
    toasterService: ToasterService,
    private messageService: MessageService,
    private authService: AuthService,
    private storageService: StorageService,
  ) {
    this.toasterService = toasterService;
   }

  ngOnInit() {
    this.messageService.enviarMessage.subscribe((data:any) =>{
      this.showToast(data);

    })
  }
  showToast(data:any){
    this.getMessage(data.typeMessage, data.message)
  }

  getMessage(typeMessage, messageString){
    let message: Message;
    switch(typeMessage){
      case MessagesEnum.Error:{
        message = {
          title: 'Error',
          message: 'Se ha producido un error.',
          type: 'error'
        }
        break;
      }
      case MessagesEnum.Bienvenido:{
        var userLogued = this.storageService.getCurrentUser();
        message = {
          title: 'Bienvenido',
          message: userLogued.firstName + ' ' + userLogued.lastName,
          type: 'success'
        }
        break;
      }
      case MessagesEnum.No_sesion:{
        message = {
          title: '¡Aviso!',
          message: 'No se ha iniciado sesión.',
          type: 'warning'
        }
        break;
      }
      case MessagesEnum.Sesion_cerrar:{
        message = {
          title: '¡Hasta pronto!',
          message: 'Usted a cerrado su sesión.',
          type: 'info'
        }
        break;
      }
      case MessagesEnum.Registro_exito:{
        message = {
          title: '',
          message: 'Registros actualizados satisfactoriamente.',
          type: 'success'
        }
        break;
      }
      case MessagesEnum.Registro_error:{
        message = {
          title: 'Error',
          message: 'La acción requerida no fue llevada a cabos, si el problema persiste contacte a su administrador.',
          type: 'success'
        }
        break;
      }
    }
    if(!isNullOrUndefined(messageString)){
      message.message = messageString;
    }
    this.toasterService.pop(message.type, message.title, message.message);
  }

}
