import { Injectable } from '@angular/core';
import { RestService } from './rest.service';

@Injectable()
export class ReportService {
    private endpoint = 'Reports';

    constructor(
        private restService : RestService,
    ){}

    public getAccountsInfo() {
      return this.restService.get( this.endpoint + '/Accounts/Accounting');
    }

}
