import { RestService } from './rest.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    private endpoint = 'Permissions';

    constructor(private restService : RestService){
    }

    public GetAllPermissions(){
        return this.restService.get(this.endpoint)
    }
}
