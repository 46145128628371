import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient,HttpParams } from '@angular/common/http';

export interface TableData extends Array<any> {
    [x: string]: any;
}

@Injectable()
export class UserService {
  public error: any;
  public data: TableData;

  constructor(
    private restService: RestService
  ){}

  getDataForDataTable() {
    return this.restService.get( 'users' );
  }

  addUser(user:any) {
    if(user.userId)
      return this.restService.put( 'users/update',user );
    else
      return this.restService.post( 'users/create',user );
  }

  removeUserById(user: any) {
    return this.restService.put( 'users/DeleteById' , user.userId);
  }

  removeMultiUsersById(users: any) {
    return this.restService.put( 'users/Delete' , users );
  }

  getUserById(userId: any) {
    return this.restService.post( 'users/GetUserById' , userId );
  }
}
