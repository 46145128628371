import { Directive, Input, TemplateRef, ViewContainerRef, ElementRef, OnInit, Attribute } from '@angular/core';
import { AuthService } from '../_services/auth.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  private isHidden = true;
  private claims: any;
  @Input() hasPermission:[];
  @Input() hasPermissionOp: string;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.claims = this.authService.getPermissions();
    if (this.checkPermission()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;
    if (this.hasPermissionOp == '') this.hasPermissionOp = 'AND';
    if ( this.claims) {
      for (let checkPermission of this.hasPermission) {
        const permissionFound = this.claims.find(x => x === checkPermission);
        if (permissionFound) {
          hasPermission = true;

          if (this.hasPermissionOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.hasPermissionOp === 'AND') {
            break;
          }
        }
      }
    }

    return hasPermission;
  }
}

