import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class MessageService {
  enviarMessage: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  showMessage(typeMessage:number, message?:string){
    this.enviarMessage.emit({typeMessage:typeMessage, message:message});
  }

}

