import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { PettyCashService } from '../_services/petty-cash.service';
import { DepartmentsService } from '../_services/departments.service';

@Injectable()
export class PettyCashDataResolver implements Resolve<Observable<any>> {

  constructor(
    private pettycashservice: PettyCashService,
    private departmentsservice : DepartmentsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    switch (route.data.section) {
      case "getTableData": {
        return this.pettycashservice.getDataForDataTable();
      }
      case "getDataToAdd": {
        return Observable.forkJoin(
          this.departmentsservice.getDepartmentsOptions(),
        )
      }
      case "getDataToEdit": {
        return Observable.forkJoin(
          this.departmentsservice.getDepartmentsOptions(),
          this.pettycashservice.getPettyCashById(route.params['id']),
        )
      }
    }
    return Observable.of(null).delay(0);
  }
}
