import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './messages.component';
import { ToasterModule } from 'angular2-toaster/angular2-toaster';

@NgModule({
  imports: [
    CommonModule,
    ToasterModule
  ],
  exports:[
    MessagesComponent
  ],
  declarations: [MessagesComponent]
})
export class MessagesModule { }
