// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-dev',
  jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiIyODNiYTk1Ni0wOGUwLTRmMWUtOGMwYy05Yjc3YjllYWVkMjAiLCJzdWIiOiJkZXZlbG9wbWVudEBjcmVkaXNvbHVjaW9uZXMuY29tIiwianRpIjoiMTZhMGM5YzAtMjM4ZS00MDk4LTg0MDQtYTM5YzQ4MDIzYzE1IiwiaWF0IjoxNTgyMzA5NDU2LCJQb3J0YWxNYW5hZ21lbnRQb2xpY3kiOiJhcGlfYWNjZXNzIiwibmJmIjoxNTgyMzA5NDU2LCJleHAiOjE1ODIzMTY2NTYsImlzcyI6IlNvbGFyQXBwSXNzdWVyIiwiYXVkIjoiU29sYXJBcHAifQ.YD7icsIVRV3w9ZkfQdm2nx86VcP3wfkgak9pX-TvS_I',
  apikeyPlaces: 'AIzaSyCZLrf0Rg-5EL3MRqhjj3zGfElNwXUZ_ko',
  apiUrlBase: 'http://119.8.10.83:5001/api/',
  apiTestUrlBase: 'http://119.8.10.83/',

};
